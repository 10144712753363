import qs from 'query-string';

import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import {STOCK_IN_QUERY_KEY} from "./stock-in.query.api";

export function useExportStockInList() {
    return useMutation(({ queryFilter, bodyFilter }) =>
        axios.post(`/api/v1/admin/stock-in-report/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
    );
}
export function useExportStockInListDetails() {
    return useMutation(({ recordId }) =>
        axios.get(`/api/v1/admin/stock-in-report/export/${recordId}`).then((res) => res.data)
    );
}

export function useApproveInStock() {
    const queryClient = useQueryClient();
    return useMutation(
        ({ stockInReportId }) =>
            axios
                .put(`/api/v1/admin/stock-in-report/approve/${stockInReportId}`)
                .then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.refetchQueries([STOCK_IN_QUERY_KEY.STOCK_IN_QUERY_LIST]);
                queryClient.refetchQueries([STOCK_IN_QUERY_KEY.STOCK_IN_QUERY_LIST_DETAILS]);
                queryClient.refetchQueries([STOCK_IN_QUERY_KEY.STOCK_IN_QUERY_LIST_ACTION_LOG]);
            },
        }
    );
}
