import {useParams} from "react-router-dom";
import {useMemo, useState} from "react";
import {StandartImageComponent} from "../../../components/image/standart-image.component";
import {Tooltip} from "flowbite-react";
import {MainDashboardLayoutComponent} from "../../../components/main-layout/main.component";
import {catchErrorMessage} from "../../../ui-utils/string.utils";
import {BounceLoading} from "../../../components/loader/bounce.loading";
import clsx from "clsx";
import {getStatuClassNameByStockTransferStatus} from "../../stock-transfer/stock-transfer.constant";
import {capitalize} from "lodash";
import {BasicTableComponent} from "../../../components/table/table-basic.component";
import {TableButtonExport} from "../../../components/table/table-button-export.component";
import {ModalConfirmation} from "../../../components/modal/moda.confirmation.component";
import {PATH_CONSTANT} from "../../../config/path.constant";
import {useGetStockInActionLog, useGetStockInDetails} from "../../../api/stock-in/stock-in.query.api";
import {useApproveInStock, useExportStockInListDetails} from "../../../api/stock-in/stock-in.mutation";

const BREAD_CUMB_ITEMS = [
    {
        title: 'Stock In',
        path: PATH_CONSTANT.STOCK_IN.LIST,
    },
    {
        title: 'Stock In List',
        path: PATH_CONSTANT.STOCK_IN.LIST,
    },
    {
        title: 'Detail',
        path: null,
    },
];

export const StockInDetail = () => {
    const {id: recordId} = useParams();

    const [modals, setModals] = useState({
        export: false,
        updateStatus: false,
        result: false
    });
    const {mutate: approveStockIn, isLoading: isApproving} = useApproveInStock();
    const {mutate: exportStockInDetailReport, isLoading: isExporting} = useExportStockInListDetails();
    const {data: stockInDetails, isLoading, isError, error} = useGetStockInDetails(recordId);
    const {data: stockInActionLog} = useGetStockInActionLog(recordId);

    const columns = useMemo(
        () => [
            {
                name: 'Product',
                className: 'w-[50%] text-sm font-semibold text-green text-center pr-3',
                dataIndex: 'user',
                render: (record) => (
                    <div className='w-[50%] text-sm font-semibold text-green md:pr-1 pr-3'>
                        <div class='flex items-center pl-2 xl:pl-5'>
                            <div class='w-12'>
                                <StandartImageComponent
                                    src={record?.product_image || '/img/default-product.jpg'}
                                    class='w-12 h-12 object-cover'
                                    alt='Product'
                                />
                            </div>
                            <div class='w-[calc(100%-3rem)] pl-3 xl:pl-5 line-clamp-3'>
                                <Tooltip className='max-w-[30%]' content={record?.product_name}>
                                    <div class='block text-xs font-semibold text-dark'>{record?.product_name}</div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                name: 'Expired Date',
                className:
                    'w-[30%] text-center text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap',
                dataIndex: 'expired_date_formatted',
                render: (record, expired_date_formatted) => (
                    <div class='w-[30%]'>
                        <div class='text-sm text-center font-medium  text-gray'>{expired_date_formatted}</div>
                    </div>
                ),
            },

            {
                name: 'QTY',
                className:
                    'w-[20%] text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap text-center',
                dataIndex: 'qty',
                render: (record, qty) => (
                    <div class='w-[20%]'>
                        <div class='text-sm font-medium text-center text-gray'>{qty ? qty?.toLocaleString() : '0'}</div>
                    </div>
                ),
            },
        ],
        []
    );

    const handleOnApproveStockIn = () => {
        approveStockIn(
            {
                stockInReportId: recordId,
            },
            {
                onSuccess: () => {
                    setModals({...modals, updateStatus: false, result: true});
                },
            }
        );
    };

    const handleExport = () => {
        exportStockInDetailReport(
            {recordId},
            {
                onSuccess: (res) => {
                    const link = document.createElement('a');
                    link.href = res.data.download_url;
                    document.body.appendChild(link);
                    link.click();
                    setModals({...modals, export: false});
                },
            }
        );
    };

    return (
        <MainDashboardLayoutComponent
            breadCumbs={BREAD_CUMB_ITEMS}
            isError={isError}
            errorTitle={error?.response?.data?.status}
            erroMessage={catchErrorMessage(error?.response?.data?.message)}
        >
            {isLoading ? (
                <div className='h-[70vh] flex items-center'>
                    <BounceLoading color='#5E755A'/>
                </div>
            ) : (
                <>
                    <div className='flex w-full justify-between pb-8'>
                        <div className='w-[calc(100%-290px)]'>
                            <div class=' bg-gray-3 px-6 pb-8 mb-5'>
                                <div
                                    className='flex justify-between mb-4 py-2 flex-row items-center  border-solid border-b border-gray-1'>
                                    <div className='flex flex-row items-center'>
                                        <StandartImageComponent
                                            src={stockInDetails?.data?.user_image}
                                            className='w-8 h-8 rounded-full border-2 border-solid border-green-2'
                                        />

                                        <div
                                            class='overflow-hidden ml-2  text-ellipsis whitespace-nowrap text-sm font-medium text-green'>
                                            {stockInDetails?.data?.user_name}
                                        </div>
                                        <div
                                            class={clsx(
                                                'inline-block py-1 px-1 ml-5 rounded-sm  text-sm font-bold',
                                                getStatuClassNameByStockTransferStatus(stockInDetails?.data?.status)
                                            )}
                                        >
                                            {capitalize(stockInDetails?.data?.status)}
                                        </div>
                                    </div>
                                    {stockInDetails?.data?.date_received && (
                                        <div className='flex flex-row items-center'>
                                            <span class='icon-ico-calendar text-sm text-green-3 font-normal'></span>
                                            <div
                                                class='overflow-hidden ml-2  text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                                                {`Received at ${stockInDetails?.data?.date_received}`}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='flex items-center justify-around'>
                                    <div className='w-[calc(100%-3rem)] pl-3'>
                                        <div
                                            className='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                                            Store Pengirim
                                        </div>
                                        <div
                                            className='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-semibold text-green mb-2 capitalize'>
                                            {stockInDetails?.data?.sender === 'other' ? stockInDetails?.data?.store_name : stockInDetails?.data?.sender}
                                        </div>
                                    </div>
                                    <div className='w-[calc(100%-3rem)] pl-3'>
                                        <div
                                            className='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                                            Store Penerima
                                        </div>
                                        <div
                                            className='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-semibold text-green mb-2'>
                                            {stockInDetails?.data?.receiver_name}
                                        </div>
                                    </div>
                                    <div className='flex items-center'>
                                        <span class='icon-ico-stock text-[40px] text-green-3 font-normal'></span>
                                        <div className='w-[calc(100%-2rem)] pl-3'>
                                            <div
                                                className='overflow-hidden font-bold text-ellipsis whitespace-nowrap text-sm  text-gray'>
                                                {`Total ${stockInDetails?.data?.total_qty?.toLocaleString()} products`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className='italic text-dark text-sm mb-5'>{`Total ${stockInDetails?.data?.items?.length} items`}</div>
                            <BasicTableComponent
                                rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
                                columns={columns}
                                dataSource={stockInDetails?.data?.items || []}
                            />
                        </div>
                        <div class='w-[270px]'>
                            <TableButtonExport
                                onClick={handleExport}
                                // loading={isExporting}
                                title='Export Data'
                                className='outline-none w-full flex items-center border justify-center border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3 mb-3'
                            />

                            {stockInDetails?.data?.status === 'submitted' && (
                                <div class='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                                    <div class='block text-sm font-semibold text-dark mb-3'>Stock In Confirmation</div>
                                    <button
                                        class='bg-green flex
                  disabled:opacity-50 disabled:cursor-not-allowed
                  justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                                        type='button'
                                        onClick={() => {
                                            setModals((prev) => ({
                                                ...prev,
                                                updateStatus: true,
                                            }));
                                        }}
                                    >
                                        <span class='icon-ico-apply mr-3 text-lg'></span> Approve
                                    </button>
                                </div>
                            )}

                            <div class='bg-gray-3 py-5 px-4 mb-3'>
                                <div class='block text-sm font-semibold text-dark mb-6'>Stock In Log</div>

                                <ul class='step max-h-[45vh] scroll'>
                                    {stockInActionLog?.data?.map((actionLogItem) => (
                                        <li>
                                            <div class='bullet'></div>
                                            <div
                                                class='text-sm font-semibold text-gray '>{actionLogItem?.action_description}</div>
                                            <div
                                                class='text-xs font-semibold italic text-gray mb-2'>{`Modified by ${actionLogItem?.actor_email}`}</div>
                                            <div
                                                class='text-sm font-medium italic text-gray'>{actionLogItem?.created_at_formatted}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div class='bg-gray-3 py-5 px-4 max-h-[45vh] scroll'>
                                <div class='block text-sm font-semibold text-dark mb-3'>Notes BA</div>
                                <p class='text-gray text-sm mb-4 whitespace-pre-line'>{stockInDetails?.data?.note}</p>

                                <div className='flex flex-row flex-wrap gap-2 mb-3'>
                                    {stockInDetails?.data?.images?.map((image) => (
                                        <StandartImageComponent
                                            defaultImage='/img/image-not-found.png'
                                            src={image?.image_url}
                                            withZoom
                                            className='h-12 w-auto  border-2 border-border-green'
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <ModalConfirmation
                description='Apakah anda yakin ingin mengubah status stock in ini ?'
                title='Update Stock In Status'
                imageIcon='/img/info.svg'
                visible={modals.updateStatus}
                loading={isApproving}
                textConfirm='Update'
                onConfirm={handleOnApproveStockIn}
                onClose={() => {
                    setModals({...modals, updateStatus: false});
                }}
            />
        </MainDashboardLayoutComponent>
    );
}
