import axios from 'api/api.config';
import qs from 'query-string';
import {useQuery} from 'react-query';

export const STOCK_IN_QUERY_KEY = {
    STOCK_IN_QUERY_LIST: 'STOCK_IN_QUERY_LIST',
    STOCK_IN_QUERY_LIST_DETAILS: 'STOCK_IN_QUERY_LIST_DETAILS',
    STOCK_IN_QUERY_LIST_ACTION_LOG: 'STOCK_IN_QUERY_LIST_ACTION_LOG',
};

export function useListStockIn({ filter, bodyFilter }) {
    return useQuery([STOCK_IN_QUERY_KEY.STOCK_IN_QUERY_LIST, { filter, bodyFilter }], () =>
        axios.post(`/api/v1/admin/stock-in-report?${qs.stringify(filter)}`, bodyFilter).then((res) => res.data)
    );
}

export function useGetStockInDetails(stockInId) {
    return useQuery([STOCK_IN_QUERY_KEY.STOCK_IN_QUERY_LIST_DETAILS, stockInId], () =>
        axios.get(`/api/v1/admin/stock-in-report/detail/${stockInId}`).then((res) => res.data)
    );
}

export function useGetStockInActionLog(recordId) {
    return useQuery([STOCK_IN_QUERY_KEY.STOCK_IN_QUERY_LIST_ACTION_LOG, recordId], () =>
        axios.get(`/api/v1/admin/stock-in-report/log/${recordId}`).then((res) => res.data)
    );
}
