import {MainDashboardLayoutComponent} from "../../../components/main-layout/main.component";
import {Link, useSearchParams} from "react-router-dom";
import {useState} from "react";
import {useUserRoleList} from "../../../api/users/user-role/user-role.query.api";
import {ModalConfirmation} from "../../../components/modal/moda.confirmation.component";
import {toast} from "react-toastify";
import {useDeleteUserRoleDetail} from "../../../api/users/user-role/user-role.mutation.api";

const BREAD_CRUMB_ITEMS = [
    {
        title: 'Users',
        path: '/users/role',
    },
    {
        title: 'User Role',
        path: '/users/role',
    },
];

export const UserRoleComponent = () => {
    const [searchParams] = useSearchParams();
    const INITIAL_VALUE_FILTER_QUERY = {
        limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
        page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
        orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'total_capabilities',
        orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'asc',
        search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
    };
    const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
    const {data: roleList, isLoading, isError, error} = useUserRoleList({filter: queryFilter});
    const { mutate: deleteRoleById, isLoading: isDeleting } = useDeleteUserRoleDetail();
    const [isDelete, setIsDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const onShowDeleteAlert = (item) => {
        setSelectedItem(item)
        setIsDelete(true);
    }

    const handleDelete = async () => {
        deleteRoleById(
            selectedItem.id,
            {
                onSuccess: () => {
                    setIsDelete(false);
                    setSelectedItem(null);
                    toast.success(`User role berhasil dihapus!`);
                },
            }
        );
    }

    return (
        <MainDashboardLayoutComponent breadCumbs={BREAD_CRUMB_ITEMS}>
            <div className={"space-y-5 mt-5"}>
                <div className={"flex flex-row items-center justify-between bg-green-status px-10 py-4 rounded-lg"}>
                    <p className={"text-monstera text-lg font-Montserrat-Bold"}>User Role</p>
                    <Link
                        className={"row items-center space-x-3 bg-border-green hover:bg-monstera px-7 py-2 rounded-md"}
                        to={"/users/role/create"}>
                        <p className={"text-white font-bold text-xs"}>Create User Role</p>
                    </Link>
                </div>
            </div>
            <div className={"flex flex-col space-y-3 pt-10"}>
                {
                    !isLoading && roleList?.data?.rows?.length > 0 &&
                    roleList?.data?.rows.map((item, index) => (
                        <div key={index}
                            className={"flex flex-row items-center text-base text-forged-steel justify-between px-10 py-4 rounded-lg border border-green-1 grid-cols-5"}>
                            <p className={"font-Montserrat-Bold w-1/3"}>{item.name}</p>
                            <p className={"font-semibold text-black-mana"}>{item.total_capabilities} Access</p>
                            <div className={"flex flex-row items-center space-x-3 text-xs font-semibold justify-end"}>
                                <button className={"flex flex-row items-center space-x-2"}
                                        onClick={() => onShowDeleteAlert(item)}>
                                    <span className='icon-ico-delete text-red-1 text-lg'></span>
                                    <p>Delete</p>
                                </button>
                                <div className={"w-[1px] h-5 bg-slate-300"}/>
                                <Link to={`/users/role/detail/${item?.id}`}>
                                    <button className={"flex flex-row items-center space-x-2"}>
                                        <span className='icon-ico-pencil-edit text-yellow text-lg'></span>
                                        <p>Edit</p>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    ))
                }
            </div>
            <ModalConfirmation
                description='Apakah anda yakin ingin menghapus data yang anda pilih ?'
                title='Hapus Data'
                visible={isDelete}
                onConfirm={handleDelete}
                imageIcon='/img/delete.png'
                onClose={() => setIsDelete(false)}
                textConfirm='Delete'
            />
        </MainDashboardLayoutComponent>
    )
}
