import {useMutation, useQueryClient} from "react-query";
import axios from "../../api.config";
import {USER_ROLE_QUERY_KEY} from "./user-role.query.api";

export function useCreateUserRole() {
    const queryClient = useQueryClient();
    return useMutation((body) => axios.post(`/api/v1/admin/role/create`, body).then((res) => res.data), {
        onSuccess: () => {
            queryClient.removeQueries([USER_ROLE_QUERY_KEY.USER_ROLE_LIST]);
            queryClient.removeQueries([USER_ROLE_QUERY_KEY.USER_ROLE_DETAIL]);
        },
    });
}

export function useEditUserRole() {
    const queryClient = useQueryClient();
    return useMutation(({ id, body }) => axios.put(`/api/v1/admin/role/update/${id}`, body).then((res) => res.data), {
        onSuccess: () => {
            queryClient.removeQueries([USER_ROLE_QUERY_KEY.USER_ROLE_LIST]);
            queryClient.removeQueries([USER_ROLE_QUERY_KEY.USER_ROLE_DETAIL]);
        },
    });
}

export function useDeleteUserRoleDetail() {
    const queryClient = useQueryClient();
    return useMutation((roleId) => axios.delete(`/api/v1/admin/role/delete/${roleId}`).then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.refetchQueries([USER_ROLE_QUERY_KEY.USER_ROLE_DETAIL]);
                queryClient.removeQueries([USER_ROLE_QUERY_KEY.USER_ROLE_LIST]);
                queryClient.removeQueries([USER_ROLE_QUERY_KEY.USER_ROLE_DETAIL]);
            },
        }
    );
}
