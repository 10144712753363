import * as Yup from 'yup';

/**
 * Form validation schema for /store/create
 */

export const CreateStoreValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required').max(80, 'Name is too long'),
  address: Yup.string().required('Address is required'),
  phone: Yup.string().test('phone', 'Phone number is not valid', (value) => {
    if (!value) return true;
    return value.length >= 7 && value.length <= 15;
  }),
  city_id: Yup.string().required('Location is required').nullable(),
  // display_permissions: Yup.string().required('user name is required').nullable(),
});
