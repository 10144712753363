import { Route, Routes, useLocation } from 'react-router-dom';
import { useIsFetching } from 'react-query';
import { useEffect, useState } from 'react';

import { RouteWrapper } from './private-route';

import LoginPage from 'pages/auth/login';
import ForgotPasswordPage from 'pages/auth/forgot-password';
import UserListPage from 'pages/users/user-list';
import UserCreatePage from 'pages/users/create';
import UserDetailPage from 'pages/users/user-detail';
import UserEditPage from 'pages/users/user-edit';
import StoreListPage from 'pages/store/store-list';
import StoreCreatePage from 'pages/store/store-create';
import StoreEditPage from 'pages/store/store-edit';
import ProductListPage from 'pages/product/product-list';
import ProductCreatePage from 'pages/product/product-create';
import ProductEditPage from 'pages/product/product-edit';
import ImportProductPage from 'pages/product/import-product';
import ImportStockStorePage from 'pages/product/stock-in-store/stock-in-store-import';
import StockInStoreListPage from 'pages/product/stock-in-store/stock-in-store-list';
import StockInStoreDetailPage from 'pages/product/stock-in-store/stock-in-store-detail';
import ReportBASalesPage from 'pages/report-ba/sales/report-sales-list';
import ReportBASalesDetailsPage from 'pages/report-ba/sales/report-sales-details';
import AnalyticsSalesPage from 'pages/analytics/sales';
import AnalyticsProductPage from 'pages/analytics/product';
import AnalyticsPresensiPage from 'pages/analytics/presensi';
import AnalyticsTrafficPage from 'pages/analytics/traffic';
import SettingMobileAppVersion from 'pages/settings/mobile-app-version';
import StockMovementPOListPage from 'pages/stock-movement/pre-order/list';
import StockMovementPOCreatePage from 'pages/stock-movement/pre-order/create';
import StockMovementPOCreateEdit from 'pages/stock-movement/pre-order/edit';
import ApprovementListPage from 'pages/stock-movement/approvement/list';
import ApprovementDetailPage from 'pages/stock-movement/approvement/po-approvement-detail';
import ApprovementWaitingPaymentDetailPage from 'pages/stock-movement/pre-order/details';
import PickingListPage from 'pages/stock-movement/picking/list';
import PickingInputExpiredDatePage from 'pages/stock-movement/picking/input-expired-date';
import PackingListPage from 'pages/stock-movement/packing/list';
import OutbondListPage from 'pages/stock-movement/outbond/list';
import PageNotFound from 'pages/error/error.page';
import { PATH_CONSTANT } from 'config/path.constant';
import { useConfirmOnUnload } from 'ui-utils/hooks/useConfirmUnload';
import PresenceListPage from 'pages/schedules/presence/list';
import PresenceDetailPage from 'pages/schedules/presence/details';
import PresensiListPage from 'pages/schedules/presensi/list';
import PresensiDetailPage from 'pages/schedules/presensi/detail';
import ImportJadwalUserPage from 'pages/schedules/presence/import';
import ChangeScheduleRequestListPage from 'pages/schedules/req-ganti-jadwal/list';
import ChangeScheduleRequestDetailPage from 'pages/schedules/req-ganti-jadwal/details';
import LemburListPage from 'pages/schedules/lembur/list';
import LemburDetailsPage from 'pages/schedules/lembur/details';
import StockTransferListPage from 'pages/stock-transfer/list';
import StockTransferDetailsPage from 'pages/stock-transfer/details';
import ReturnProductListPage from 'pages/return-product/list';
import ReturnProductDetailsPage from 'pages/return-product/details';
import ReportBAVisitorListPage from 'pages/report-ba/visitor';
import ReportBANEDProductListPage from 'pages/report-ba/ned-product/ned-product-list';
import ReportBANEDProductDetailsPage from 'pages/report-ba/ned-product/ned-product-details';
import ReportCycleCountListPage from 'pages/report-ba/cycle-count/cycle-count-list';
import ReportCycleCountDetailsPage from 'pages/report-ba/cycle-count/cycle-count-details';
import ShareInfoCreateInfoPage from 'pages/share-info/create-info';
import ShareInfoListPage from 'pages/share-info/list-info';
import ShareInfoDetailsPage from 'pages/share-info/details-info';
import ShareInfoEditInfoPage from 'pages/share-info/edit-info';
import ShareInfoListSkillDevelopment from 'pages/share-info/list-skill-development';
import ShareInfoCreateSkillDevelopment from 'pages/share-info/create-skill-development';
import ShareInfoDetailsSkillDevelopment from 'pages/share-info/details-skill-development';
import ShareInfoEditSkillDevelopment from 'pages/share-info/edit-skill-development';
import {TimeOffList} from "../pages/time-off";
import TimeOffSetting from "../pages/settings/time-off-setting";
import StockInListComponent from "../pages/stock-in/list";
import StockInDetail from "../pages/stock-in/detail";
import UserRole from "../pages/users/user-role";
import UserRoleDetail from "../pages/users/user-role-detail";
import UserRoleCreateComponent from "../pages/users/create-role";

export default function RoutePage() {
  const { pathname } = useLocation();
  const isFetching = useIsFetching();

  const preventLeave = isFetching;

  useConfirmOnUnload(preventLeave, 'Are you sure you want to leave?');

  const [routeList] = useState([
    {
      path: `${PATH_CONSTANT.USER.USER_DETAILS}/:userId`,
      component: <UserDetailPage />,
      isPrivate: true,
    },
    {
      path: `${PATH_CONSTANT.USER.USER_EDIT}/:userId`,
      component: <UserEditPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.USER.USER_LIST,
      component: <UserListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.USER.USER_CREATE,
      component: <UserCreatePage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.USER.USER_ROLE,
      component: <UserRole />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.USER.USER_ROLE_CREATE,
      component: <UserRoleCreateComponent />,
      isPrivate: true,
    },
    {
      path: `${PATH_CONSTANT.USER.USER_ROLE_DETAIL}/:roleId`,
      component: <UserRoleDetail />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STORE.STORE_LIST,
      component: <StoreListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STORE.STORE_CREATE,
      component: <StoreCreatePage />,
      isPrivate: true,
    },
    {
      path: `${PATH_CONSTANT.STORE.STORE_EDIT}/:storeId`,
      component: <StoreEditPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.PRODUCT.PRODUCT_LIST,
      component: <ProductListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.PRODUCT.PRODUCT_CREATE,
      component: <ProductCreatePage />,
      isPrivate: true,
    },
    {
      path: `${PATH_CONSTANT.PRODUCT.PRODUCT_EDIT}/:productId`,
      component: <ProductEditPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_LIST,
      component: <StockInStoreListPage />,
      isPrivate: true,
    },
    {
      path: `${PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_INFO}/:storeId`,
      component: <StockInStoreDetailPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.PRODUCT.PRODUCT_IMPORT,
      component: <ImportProductPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST,
      component: <StockMovementPOListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.APPROMENT_LIST,
      component: <ApprovementListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.APPROVEMENT_PO_DETAILS + '/:poId',
      component: <ApprovementWaitingPaymentDetailPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.PICKING_LIST,
      component: <PickingListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.PACKING_LIST,
      component: <PackingListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.PACKING_DETAILS + '/:poId',
      component: <ApprovementWaitingPaymentDetailPage />,
      isPrivate: true,
    },

    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.OUTBOND_LIST,
      component: <OutbondListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.OUTBOND_DETAILS + '/:poId',
      component: <ApprovementWaitingPaymentDetailPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.PICKING_INPUT_EXPIRED_DATE + '/:poId',
      component: <PickingInputExpiredDatePage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.PICKING_DETAILS + '/:poId',
      component: <ApprovementWaitingPaymentDetailPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.APPROMENT_DETAILS + '/:poId',
      component: <ApprovementDetailPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.PO_DETAILS + '/:poId',
      component: <ApprovementWaitingPaymentDetailPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_MOVEMENT.PO_CREATE,
      component: <StockMovementPOCreatePage />,
      isPrivate: true,
    },
    {
      path: `${PATH_CONSTANT.STOCK_MOVEMENT.PO_EDIT}/:poId`,
      component: <StockMovementPOCreateEdit />,
      isPrivate: true,
    },
    {
      path: `${PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_IMPORT}/:storeId`,
      component: <ImportStockStorePage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.AUTH.LOGIN,
      component: <LoginPage />,
      isPrivate: false,
    },
    {
      path: PATH_CONSTANT.AUTH.FORGOT_PASSWORD,
      component: <ForgotPasswordPage />,
      isPrivate: false,
    },
    {
      path: PATH_CONSTANT.REPORT_BA.SALES,
      component: <ReportBASalesPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.REPORT_BA.DETAILS + '/:recordId',
      component: <ReportBASalesDetailsPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.REPORT_BA.VISITOR,
      component: <ReportBAVisitorListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.REPORT_BA.NED_PRODUCT,
      component: <ReportBANEDProductListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.REPORT_BA.NED_PRODUCT_DETAILS + '/:recordId',
      component: <ReportBANEDProductDetailsPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.REPORT_BA.CYCLE_COUNT,
      component: <ReportCycleCountListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.REPORT_BA.CYCLE_COUNT_DETAILS + '/:recordId',
      component: <ReportCycleCountDetailsPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.ANALYTICS.SALES,
      component: <AnalyticsSalesPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.ANALYTICS.PRODUCT,
      component: <AnalyticsProductPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.ANALYTICS.PRESENSI,
      component: <AnalyticsPresensiPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.ANALYTICS.TRAFFIC,
      component: <AnalyticsTrafficPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SETTINGS.MOBILE_VERSION,
      component: <SettingMobileAppVersion />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SETTINGS.REQUEST_CUTI,
      component: <TimeOffSetting />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SCHEDULE.LIST,
      component: <PresenceListPage />,
      isPrivate: true,
    },
    {
      path: `${PATH_CONSTANT.SCHEDULE.DETAILS}/:userId`,
      component: <PresenceDetailPage />,
      isPrivate: true,
    },
    {
      path: `${PATH_CONSTANT.SCHEDULE.IMPORT}/:userId`,
      component: <ImportJadwalUserPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.PRESENSI.LIST,
      component: <PresensiListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.PRESENSI.DETAILS + '/:userId',
      component: <PresensiDetailPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.CUTI.LIST,
      component: <TimeOffList />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.GANTI_JADWAL.LIST,
      component: <ChangeScheduleRequestListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.GANTI_JADWAL.DETAILS + '/:id',
      component: <ChangeScheduleRequestDetailPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.OVERTIME.LIST,
      component: <LemburListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.OVERTIME.DETAILS + '/:id',
      component: <LemburDetailsPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_TRANSFER.LIST,
      component: <StockTransferListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_TRANSFER.DETAILS + '/:id',
      component: <StockTransferDetailsPage />,

      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_IN.LIST,
      component: <StockInListComponent />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.STOCK_IN.DETAILS + '/:id',
      component: <StockInDetail />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.RETURN_PRODUCT.LIST,
      component: <ReturnProductListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.RETURN_PRODUCT.DETAILS,
      component: <ReturnProductDetailsPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SHARE_INFO.CREATE_INFO,
      component: <ShareInfoCreateInfoPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SHARE_INFO.LIST_INFO,
      component: <ShareInfoListPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SHARE_INFO.DETAILS_INFO,
      component: <ShareInfoDetailsPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SHARE_INFO.EDIT_INFO,
      component: <ShareInfoEditInfoPage />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SHARE_INFO.LIST_INFO_SKILL_DEVELOPMENT,
      component: <ShareInfoListSkillDevelopment />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SHARE_INFO.CREATE_INFO_SKILL_DEVELOPMENT,
      component: <ShareInfoCreateSkillDevelopment />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SHARE_INFO.DETAILS_SKILL_DEVELOPMENT,
      component: <ShareInfoDetailsSkillDevelopment />,
      isPrivate: true,
    },
    {
      path: PATH_CONSTANT.SHARE_INFO.EDIT_INFO_SKILL_DEVELOPMENT,
      component: <ShareInfoEditSkillDevelopment />,
      isPrivate: true,
    },
    {
      path: '',
      exact: true,
      isPrivate: true,
      component: null,
    },
  ]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <Routes>
      {routeList.map((item, index) => (
        <Route
          key={index.toString()}
          path={item.path}
          element={<RouteWrapper isPrivate={item.isPrivate}>{item.component}</RouteWrapper>}
        />
      ))}
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
}
