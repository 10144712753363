import {Formik} from 'formik';
import {toast} from 'react-toastify';
import {useMemo, useRef, useState} from 'react';
import {MainDashboardLayoutComponent} from 'components/main-layout/main.component';
import {InputComponent} from 'components/form/input.component';
import {Select} from 'components/form/select.component';
import {ButtonSubmit} from 'components/button/button-submit.component';
import {BounceLoading} from 'components/loader/bounce.loading';
import {useNavigate} from 'react-router-dom';
import {useMasterCityList} from 'api/master-data/master-data.api';
import {CreateStoreValidationSchema} from 'config/form/store/store-validation.schema';
import {useCreateStore} from 'api/store/store-create/store-create.mutation.api';
import {UPLOAD_IMAGE_CONSTANT, useUploadSingleImage} from 'api/upload/upload-single-image.api';
import {ModalConfirmation} from 'components/modal/moda.confirmation.component';
import {PATH_CONSTANT} from 'config/path.constant';
import {useAllUserList} from "../../../api/users/user-list/user-list.query.api";
import {SelectMultiple} from "../../../components/form/select-multiple.component";

const BREAD_CUMB_ITEMS = [
    {
        title: 'Store',
        path: '/store/list',
    },
    {
        title: 'Create Store',
        path: '/store/create',
    },
];

const formInitialValue = {
    city_id: null,
    name: '',
    owner_name: '',
    type: '',
    address: '',
    phone: '',
    image: null,
    display_permissions: []
};

const storeType = [
    {id: 'general_trade', name: 'General Trade'},
    {id: 'avostore', name: 'Avostore'},
    {id: 'modern_trade', name: 'Modern Trade'},
    {id: 'distributor', name: 'Distributor'}
]

export const StoreCreateComponent = () => {
    const navigate = useNavigate();
    const formikRef = useRef(null);

    const [modalConfirmation, setModalConfirmation] = useState(false);
    const {data: masterCityList, isLoading} = useMasterCityList();
    const {data: masterUserList} = useAllUserList();
    const {mutateAsync: createStore, isLoading: isCreating} = useCreateStore();

    const {mutateAsync: uploadStoreImage, isLoading: isUploading} = useUploadSingleImage({
        type: UPLOAD_IMAGE_CONSTANT.TYPE.COMPRESSED,
        path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.STORE_IMAGE,
    });

    const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('/img/default-store.jpg');
    const [selectedBA, setSelectedBA] = useState([]);

    const userOptions = useMemo(() => {
        if (!!masterUserList?.data?.length) {
            return masterUserList?.data?.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        }
        return [];
    }, [masterUserList]);

    const storeOptions = useMemo(() => {
        if (masterCityList) {
            return masterCityList?.data?.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        }
        return [];
    }, [masterCityList]);

    const handleOnFormSubmit = async (formValues) => {
        try {
            let profilePictureURL = null;
            if (selectedFileBuffer) {
                const formData = new FormData();
                formData.append('image', selectedFileBuffer);
                const response = await uploadStoreImage(formData);
                profilePictureURL = response?.data?.url;
            }
            const payload = {
                ...formValues,
                image: profilePictureURL,
                phone: `+62${formValues.phone}`,
                display_permissions: selectedBA?.map(item => item?.id) || []
            };

            if (!formValues.phone) payload.phone = '';
            await createStore(payload);
            toast.success('Store created successfully', {
                autoClose: 2000,
            });
            navigate(PATH_CONSTANT.STORE.STORE_LIST);
        } catch (error) {
            toast.error('Error create store', {
                autoClose: 2000,
            });
        }
    };

    const handleOnUploadFileChange = (e) => {
        //chedck file size with maximum 5mb and type, only accept image
        if (e.target.files[0].size > 5000000) {
            toast('File size is too large. Maximum 5mb is allowed');
            return;
        }
        if (!e.target.files[0].type.includes('image')) {
            toast('Only image file is allowed');
            return;
        }
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            setSelectedFileBuffer(file);
            setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const isDisabledAllForm = isCreating || isUploading;

    return (
        <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
            <div className='flex'>
                {isLoading ? (
                    <div className='h-[70vh] flex w-full items-center text-center'>
                        <BounceLoading color='#5E755A'/>
                    </div>
                ) : (
                    <Formik
                        innerRef={formikRef}
                        initialValues={formInitialValue}
                        onSubmit={handleOnFormSubmit}
                        validationSchema={CreateStoreValidationSchema}
                    >
                        {({errors, values, handleChange, handleBlur, touched}) => (
                            <>
                                <div className='w-64 flex justify-center'>
                                    <div className='w-auto'>
                                        <img
                                            src={imagePreviewUrl}
                                            className='w-40 h-40 object-cover object-center overflow-hidden'
                                            alt='Preview'
                                        />
                                        <div className='mt-7 flex justify-center'>
                                            <input
                                                type='file'
                                                id='upload'
                                                className='hidden'
                                                onChange={handleOnUploadFileChange}
                                                accept='image/*'
                                            />
                                            <label
                                                htmlFor='upload'
                                                className='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                                            >
                                                <span className='icon-ico-upload mr-2'></span>
                                                <span className='text-sm'>Upload</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-1/2'>
                                    <InputComponent
                                        disabled={isDisabledAllForm}
                                        name='name'
                                        value={values.name}
                                        error={touched.name && errors.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label='Nama Store'
                                        placeholder='Input Nama Store'
                                    />
                                    <InputComponent
                                        disabled={isDisabledAllForm}
                                        name='owner_name'
                                        value={values.owner_name}
                                        error={touched.owner_name && errors.owner_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label='Nama Pemilik Store'
                                        placeholder='Input Nama Pemilik Store'
                                    />
                                    <Select
                                        disabled={isDisabledAllForm}
                                        name='type'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.type}
                                        options={storeType}
                                        error={touched.type && errors.type}
                                        placeholder='Pilih Tipe Store'
                                        containerClassName='form-input  relative mb-3'
                                        label='Tipe Store'
                                    />

                                    <Select
                                        disabled={isDisabledAllForm}
                                        name='city_id'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.city_id}
                                        options={storeOptions}
                                        error={touched.city_id && errors.city_id}
                                        placeholder='Pilih Lokasi Store'
                                        containerClassName='form-input  relative mb-3'
                                        label='Lokasi Store'
                                    />

                                    <SelectMultiple
                                        disabled={isDisabledAllForm}
                                        name='display_permissions'
                                        onChange={(e) => setSelectedBA(e?.target?.value)}
                                        onBlur={handleBlur}
                                        value={selectedBA}
                                        options={userOptions}
                                        error={touched.display_permissions && errors.display_permissions}
                                        placeholder='Pilih Nama BA'
                                        containerClassName='form-input  relative mb-3'
                                        label='Daftar BA'
                                    />

                                    <InputComponent
                                        disabled={isDisabledAllForm}
                                        name='address'
                                        element='textarea'
                                        rows={7}
                                        value={values.address}
                                        error={touched.address && errors.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label='Alamat Store'
                                        placeholder='Nama jalan, lantai gedung, nomor bangunan....'
                                    />
                                    <InputComponent
                                        disabled={isDisabledAllForm}
                                        numberOnly={true}
                                        name='phone'
                                        value={values.phone}
                                        error={touched.phone && errors.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        containerClass='form-input relative mb-3'
                                        label='Nomor Telepon Store'
                                        prefix='+62'
                                        inputClass='w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 pl-16 pr-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out'
                                        type='tel'
                                        placeholder='Nomor Telepon Store'
                                    />

                                    <div className='py-14 relative flex justify-center'>
                                        <ButtonSubmit
                                            loading={isCreating | isUploading}
                                            disabled={!CreateStoreValidationSchema.isValidSync(values)}
                                            onClick={() => setModalConfirmation(true)}
                                            className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                                        >
                                            <span className='icon-ico-apply mr-3'></span> Create Store
                                        </ButtonSubmit>
                                    </div>
                                </div>
                            </>
                        )}
                    </Formik>
                )}
            </div>
            <ModalConfirmation
                description='Apakah anda yakin ingin membuat store baru?'
                title='Create Store'
                imageIcon='/img/info.svg'
                textConfirm='Create'
                loading={isCreating}
                visible={modalConfirmation}
                onConfirm={() => {
                    formikRef?.current?.handleSubmit();
                }}
                onClose={() => setModalConfirmation(false)}
            />
        </MainDashboardLayoutComponent>
    );
};
