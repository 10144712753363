import {Formik} from 'formik';
import {toast} from 'react-toastify';
import {useEffect, useMemo, useRef, useState} from 'react';
import {MainDashboardLayoutComponent} from 'components/main-layout/main.component';
import {InputComponent} from 'components/form/input.component';
import {Select} from 'components/form/select.component';
import {ButtonSubmit} from 'components/button/button-submit.component';
import {BounceLoading} from 'components/loader/bounce.loading';
import {useNavigate, useParams} from 'react-router-dom';
import {useMasterCityList} from 'api/master-data/master-data.api';
import {CreateStoreValidationSchema} from 'config/form/store/store-validation.schema';
import {
    useDeleteStoreDisplayImage,
    useEditStore,
    useGetAwsTokenForUploadS3,
    useUpdateDisplayStore,
    useUploadToS3
} from 'api/store/store-create/store-create.mutation.api';
import {useStoreDetail} from 'api/store/store-list/store-list.query.api';
import {UPLOAD_IMAGE_CONSTANT, useUploadSingleImage} from 'api/upload/upload-single-image.api';
import {catchErrorMessage} from 'ui-utils/string.utils';
import {StandartImageComponent} from "../../../components/image/standart-image.component";
import moment from "moment";
import {ModalConfirmation} from "../../../components/modal/moda.confirmation.component";
import {useAllUserList} from "../../../api/users/user-list/user-list.query.api";
import {SelectMultiple} from "../../../components/form/select-multiple.component";

const BREAD_CUMB_ITEMS = [
    {
        title: 'Store',
        path: '/store/list',
    },
    {
        title: 'Edit Store',
        path: '/store/list',
    },
];

const formInitialValue = {
    city_id: null,
    name: '',
    owner_name: '',
    type: '',
    address: '',
    phone: '',
    image: null,
    display_permissions: []
};

const storeType = [
    {id: 'general_trade', name: 'General Trade'},
    {id: 'avostore', name: 'Avostore'},
    {id: 'modern_trade', name: 'Modern Trade'},
    {id: 'distributor', name: 'Distributor'}
]

export const StoreEditComponent = () => {
    const formikRef = useRef(null);
    const {storeId} = useParams();
    const navigate = useNavigate();
    const {data: masterCityList} = useMasterCityList();
    const {data: masterUserList} = useAllUserList();
    const {data: storeDetails, isLoading, isError, error} = useStoreDetail(storeId);
    const {mutateAsync: updateStore, isLoading: isCreating} = useEditStore();
    const {mutateAsync: uploadStoreImage, isLoading: isUploading} = useUploadSingleImage({
        type: UPLOAD_IMAGE_CONSTANT.TYPE.COMPRESSED,
        path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.STORE_IMAGE,
    });
    const {mutateAsync: getS3BucketToken} = useGetAwsTokenForUploadS3();
    const {mutateAsync: uploadDataToS3, isUploading: isUploadingImage} = useUploadToS3();
    const {mutateAsync: updateUrlToDatabaseStoreDetail} = useUpdateDisplayStore();
    const {mutateAsync: deleteImageStoreDisplay} = useDeleteStoreDisplayImage();

    const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
    const [storeDisplayImgBuffer, setStoreDisplayImgBuffer] = useState([]);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('/img/default-store.jpg');
    const [storeDisplayPreviewUrls, setStoreDisplayPreviewUrls] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedBA, setSelectedBA] = useState([]);

    const userOptions = useMemo(() => {
        if (!!masterUserList?.data?.length) {
            return masterUserList?.data?.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        }
        return [];
    }, [masterUserList]);

    const storeOptions = useMemo(() => {
        if (masterCityList) {
            return masterCityList?.data?.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        }
        return [];
    }, [masterCityList]);

    useEffect(() => {
        if (storeDetails) {
            const newArray = userOptions?.filter(masterItem => {
                return storeDetails?.data?.display_permissions?.some(permission => permission.user_id === masterItem.id);
            });
            setSelectedBA(newArray)

            Object.keys(formInitialValue).forEach((key) => {
                if (storeDetails?.data[key]) {
                    if (key === 'phone') {
                        formikRef.current.setFieldValue(key, storeDetails?.data[key].replace('+62', ''));
                    }
                    else {
                        formikRef.current.setFieldValue(key, storeDetails.data[key]);
                    }
                }

                setImagePreviewUrl(storeDetails?.data?.image || '/img/default-store.jpg');
            });
        }
    }, [storeDetails, formikRef]);

    const handleOnFormSubmit = async (formValues) => {
        try {
            let storePictureURL = null;
            if (selectedFileBuffer) {
                const formData = new FormData();
                formData.append('image', selectedFileBuffer);
                const response = await uploadStoreImage(formData);
                storePictureURL = response?.data?.url;
            }
            const payload = {
                ...formValues,
                image: selectedFileBuffer ? storePictureURL : formValues.image,
                phone: `+62${formValues.phone}`,
                display_permissions: selectedBA?.map(item => item?.id) || []
            };

            if (!formValues.phone) payload.phone = '';
            await updateStore({...payload, id: storeId});
            toast.success('Store updated successfully', {
                autoClose: 2000,
            });
            navigate('/store/list');
        } catch (error) {
        }
    };

    const handleOnUploadFileChange = (e) => {
        //chedck file size with maximum 5mb and type, only accept image
        if (e.target.files[0].size > 5000000) {
            toast('File size is too large. Maximum 5mb is allowed');
            return;
        }
        if (!e.target.files[0].type.includes('image')) {
            toast('Only image file is allowed');
            return;
        }
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            setSelectedFileBuffer(file);
            setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleUploadStoreDisplay = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = [];
        const previewUrls = [];

        files.forEach((file) => {
            if (file.size > 5000000) {
                toast('File size is too large. Maximum 5MB is allowed');
                return;
            }
            if (!file.type.includes('image')) {
                toast('Only image files are allowed');
                return;
            }

            validFiles.push(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                previewUrls.push(reader.result);

                // Update state after all files are processed
                if (previewUrls.length === validFiles.length) {
                    setStoreDisplayImgBuffer(prev => [...prev, ...validFiles]); // Update state with new valid files
                    setStoreDisplayPreviewUrls(prev => [...prev, ...previewUrls]); // Update state with new preview URLs
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const handleDeleteImage = (index) => {
        setStoreDisplayPreviewUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
        setStoreDisplayImgBuffer(prevImgs => prevImgs.filter((_, i) => i !== index));
    };

    const onUploadImageStoreDisplay = async () => {
        let data = storeDisplayImgBuffer[0]

        try {
            const formData = new FormData();
            const uploadFileToken = await getS3BucketToken(data?.name);

            const preSignUrl = uploadFileToken.data?.fields;
            const uploadUrl = uploadFileToken.data?.url;

            if (preSignUrl && uploadUrl) {
                for (const key in preSignUrl) {
                    formData.append(key, preSignUrl[key]);
                }
                formData.append("file", data);

                await uploadDataToS3({formData, url: uploadUrl});
                await updateUrlToDatabaseStoreDetail({store_id: storeId, url: uploadUrl + preSignUrl.key})
                setStoreDisplayPreviewUrls([])
                setStoreDisplayImgBuffer([])

            } else {
                throw new Error("Failed to get the pre-signed URL from S3.");
            }
        } catch (error) {
            toast.error(error?.message || error.response?.data?.message || "Something went wrong");
        }
    };

    const handleDeletePrevData =  (item) => {
        setSelectedImage(item)
        setShowAlert(true)
    }

    const handleOnDeleteImage = async () => {
        try {
            await deleteImageStoreDisplay(selectedImage?.id)
            setShowAlert(false)
        }
        catch (error) {
            setShowAlert(false)
            toast.error(error?.message || error.response?.data?.message || "Something went wrong");
        }
    }

    const isDisabledAllForm = isCreating || isUploading;

    return (
        <MainDashboardLayoutComponent
            breadCumbs={BREAD_CUMB_ITEMS}
            isError={isError}
            errorTitle={error?.response?.data?.status}
            erroMessage={catchErrorMessage(error?.response?.data?.message)}
        >
            <div className=''>
                <div className={'flex'}>
                    {isLoading ? (
                        <div className='h-[70vh] flex w-full items-center text-center'>
                            <BounceLoading color='#5E755A'/>
                        </div>
                    ) : (
                        <Formik
                            innerRef={formikRef}
                            initialValues={formInitialValue}
                            onSubmit={handleOnFormSubmit}
                            validationSchema={CreateStoreValidationSchema}
                        >
                            {({errors, values, handleChange, handleBlur, handleSubmit, touched}) => (
                                <>
                                    <div class='w-64 flex justify-center'>
                                        <div class='w-auto'>
                                            <img
                                                src={imagePreviewUrl}
                                                class='w-40 h-40 object-cover object-center overflow-hidden'
                                                alt='Preview'
                                            />
                                            <div class='mt-7 flex justify-center'>
                                                <input
                                                    type='file'
                                                    id='upload'
                                                    class='hidden'
                                                    onChange={handleOnUploadFileChange}
                                                    accept='image/*'
                                                />
                                                <label
                                                    for='upload'
                                                    class='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                                                >
                                                    <span class='icon-ico-upload mr-2'></span>
                                                    <span class='text-sm'>Upload</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='w-1/2'>
                                        <InputComponent
                                            disabled={isDisabledAllForm}
                                            name='name'
                                            value={values.name}
                                            error={touched.name && errors.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label='Nama Store'
                                            placeholder='Input Nama Store'
                                        />
                                        <InputComponent
                                            disabled={isDisabledAllForm}
                                            name='owner_name'
                                            value={values.owner_name}
                                            error={touched.owner_name && errors.owner_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label='Nama Pemilik Store'
                                            placeholder='Input Nama Pemilik Store'
                                        />
                                        <Select
                                            disabled={isDisabledAllForm}
                                            name='type'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.type}
                                            options={storeType}
                                            error={touched.type && errors.type}
                                            placeholder='Pilih Tipe Store'
                                            containerClassName='form-input  relative mb-3'
                                            label='Tipe Store'
                                        />
                                        <Select
                                            disabled={isDisabledAllForm}
                                            name='city_id'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.city_id}
                                            options={storeOptions}
                                            error={touched.city_id && errors.city_id}
                                            placeholder='Pilih Lokasi Store'
                                            containerClassName='form-input  relative mb-3'
                                            label='Lokasi Store'
                                        />
                                        <SelectMultiple
                                            disabled={isDisabledAllForm}
                                            name='display_permissions'
                                            onChange={(e) => {
                                                setSelectedBA(e?.target?.value)
                                            }}
                                            onBlur={handleBlur}
                                            value={selectedBA}
                                            options={userOptions}
                                            error={touched.display_permissions && errors.display_permissions}
                                            placeholder='Pilih Nama BA'
                                            containerClassName='form-input  relative mb-3'
                                            label='Daftar BA'
                                        />
                                        <InputComponent
                                            disabled={isDisabledAllForm}
                                            name='address'
                                            element='textarea'
                                            rows={7}
                                            value={values.address}
                                            error={touched.address && errors.address}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label='Alamat Store'
                                            placeholder='Nama jalan, lantai gedung, nomor bangunan....'
                                        />
                                        <InputComponent
                                            disabled={isDisabledAllForm}
                                            numberOnly={true}
                                            name='phone'
                                            value={values.phone}
                                            error={touched.phone && errors.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            containerClass='form-input relative mb-3'
                                            label='Nomor Telepon Store'
                                            prefix='+62'
                                            inputClass='w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 pl-16 pr-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out'
                                            type='tel'
                                            placeholder='Nomor Telepon Store'
                                        />
                                        <div class='py-14 relative flex justify-center'>
                                            <ButtonSubmit
                                                loading={isCreating | isUploading}
                                                disabled={!CreateStoreValidationSchema.isValidSync(values)}
                                                onClick={handleSubmit}
                                                className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                                            >
                                                <span class='icon-ico-apply mr-3'></span> Update Store
                                            </ButtonSubmit>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Formik>
                    )}
                </div>
                <div className='w-full flex justify-start'>
                    <div className='w-auto space-y-4 ml-8'>
                        <div className='font-semibold text-xl'>Store Display</div>
                        <div className='flex flex-wrap gap-4'>
                            {
                                storeDisplayPreviewUrls?.length > 0 ?
                                    <div className='flex justify-start items-end my-4'>
                                        {storeDisplayPreviewUrls.map((url, index) => (
                                            <div key={index} className='relative'>
                                                <img
                                                    src={url}
                                                    className='w-40 h-40 object-cover object-center overflow-hidden'
                                                    alt={`Preview ${index + 1}`}
                                                />
                                                <button
                                                    onClick={() => handleDeleteImage(index)}
                                                    className='absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center'
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <div className='ml-4'>
                                            <ButtonSubmit
                                                onClick={onUploadImageStoreDisplay}
                                                className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] h-[20] text-sm px-7'
                                            >
                                                <span className='icon-ico-upload mr-2'></span>
                                                <span className='text-sm'>Upload Image</span>
                                            </ButtonSubmit>
                                        </div>
                                    </div>
                                    :
                                    <div className='flex justify-start items-end'>
                                        <div className='w-auto'>
                                            <div>
                                                <input
                                                    type='file'
                                                    id='uploadImageDisplay'
                                                    className='hidden'
                                                    onChange={handleUploadStoreDisplay}
                                                    accept='image/*'
                                                />
                                                <label
                                                    htmlFor='uploadImageDisplay'
                                                    className='bg-green inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                                                >
                                                    <span className='icon-ico-upload mr-2'></span>
                                                    <span className='text-sm'>Add Image</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className='flex flex-wrap gap-4'>
                            {
                                storeDetails?.data?.display_images?.length > 0 &&
                                storeDetails?.data?.display_images.map((item, index) => (
                                    <div key={index} className='relative'>
                                        <div className={'w-56 h-auto'}>
                                            <StandartImageComponent
                                                defaultImage='/img/image-not-found.png'
                                                src={item?.url}
                                                withZoom
                                                alt={`Preview ${index + 1}`}
                                                className='w-56 h-56 object-cover'
                                            />
                                            <div className={'text-[11px] mt-1'}>Upload by : {item?.uploader_name} </div>
                                            <div className={'text-[11px]'}>{moment(item?.upload_time).format('lll')} </div>
                                        </div>
                                        <button
                                            onClick={() => handleDeletePrevData(item)}
                                            className='absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center'
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmation
                description='Apakah anda yakin ingin menghapus data ini?'
                title='Delete Data'
                imageIcon='/img/Delete.png'
                textConfirm='Delete'
                visible={showAlert}
                onConfirm={handleOnDeleteImage}
                onClose={() => {
                    setShowAlert(false)
                    setSelectedImage(null)
                }}
            />
        </MainDashboardLayoutComponent>
    );
};
